<template>
  <div class="api-block test-mode msdk">
    <div>
      <h2 class="api-h1">MSDK</h2>
    </div>
    <p class="api-description">
      {{ $t('msdk_1') }}</p>
    <p class="api-description">
      {{ $t('msdk_2') }}
    </p>
    <p class="api-description">
     {{ $t('msdk_3') }}
    </p>
    <ul class="api-list" v-if="links">
      <li>IOS:  <a href="https://gitlab.onevision.kz/msdk/msdk-ios">https://gitlab.onevision.kz/msdk/msdk-ios</a></li>
      <li>Android: <a href="https://gitlab.onevision.kz/msdk/msdk-android">https://gitlab.onevision.kz/msdk/msdk-android</a></li>
    </ul>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
export default {
  props: {
    company_info: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(){
    const links = ref(false);

    onMounted(() => {
      if(window.location.hostname.includes('onevision')){
        links.value = true;
      }
    })

    return{
      links
    }
  }
};
</script>

<style lang="scss">
.test-mode {
  border-top: 0.8px solid rgb(115, 115, 115);
  .api-h1 {
    padding-bottom: 20px !important;
  }
}

.bt-grey {
  border-top: 1px solid gainsboro;
  padding-top: 20px !important;
  margin-top: 20px !important;
}

.msdk{
  .api-list{
    li{
      & + li{
        margin-top: 16px;
      }
      a{
        font-size: 16px;
        color: #19226d;
        font-weight: 600;
      }
    }
  }
}
</style>
